.list-images-block-item {
    flex: 0 0 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 32px;
    padding: 0 7px;

    &__image {
        max-width: 150px;
        margin-bottom: auto;
        padding-bottom: 10px;

        img {
            width: 100%;
        }
    }

    &__info {
        @include text-sticker;

        margin-bottom: 10px;
    }

    &__title {
        @include text-heading-5;

        margin: 0 0 10px;
    }

    &__info-2 {
        @include text-sticker;

        margin-bottom: 22px;
        background: $color-mine-shaft-gray;
    }

    &__info,
    &__info-2 {
        font-size: 11px;
        border-radius: 2px;
    }

    &__price {
        font-size: 14px;
        text-align: center;

        em {
            color: $color-mine-shaft-gray;
        }
    }

    &__conditions {
        @include text-small;

        margin: 40px 0 0;
    }

    @include media-breakpoint-up(md) {
        flex: 0 0 33.33%;

        &__image {
            max-width: 210px;
        }

        &__info,
        &__info-2 {
            font-size: 12px;
        }
    }

    @include media-breakpoint-up(lg) {
        flex: 0 0 25%;
        padding: 0 14px;
    }

    @include media-breakpoint-up(xl) {
        &__image {
            max-width: 220px;
        }
    }

    @include media-breakpoint-up(xxl) {
        &__image {
            max-width: 290px;
        }
    }
}
