.custom-react-tooltip {
    --rt-opacity: 1;

    .react-tooltip {
        max-width: 12.5rem;
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        font-weight: $font-weight-book;
        text-transform: none;
        text-align: center;
        line-height: 1.5;
        color: $color-white;
        background-color: $color-mine-shaft-gray;
        z-index: $zindex-tooltip;
        border-radius: 0;

        a {
            text-decoration: underline;
        }
    }
}

.tooltip {
    a {
        text-decoration: underline;
    }
}
